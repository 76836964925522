<template>
  <div class="wrapper">
    <IndexHeader />
    <router-view v-if="isRouterAlive" :key="$route.fullPath"/>
  </div>
  <IndexFooter />
</template>

<script>
import { defineAsyncComponent, defineComponent } from "vue";
import { languageSelect } from "../../public/assets/js/lang.js";
export default defineComponent({
  name: "index",
  components: {
    IndexHeader: defineAsyncComponent(() => import("./components/header.vue")),
    IndexFooter: defineAsyncComponent(() => import("./components/footer.vue")),
  },
  provide() {
    return {
      reload: this.reload,
    };
  },

  data() {
    return {
      isRouterAlive: true,
    };
  },

  mounted() {
    languageSelect();
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
});
</script>

<style lang="scss">
@import "../../public/assets/vendor/font-awesome/css/fontawesome-all.min5f15.css";
@import "../../public/assets/vendor/animate.css/animate.min5f15.css";
@import "../../public/assets/vendor/hs-megamenu/src/hs.megamenu5f15.css";
@import "../../public/assets/vendor/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar5f15.css";
@import "../../public/assets/vendor/select2/dist/css/select2.min5f15.css";
@import "../../public/assets/css/theme.min5f15.css";
@import "../../public/assets/css/custom5f15.css";
</style>
